<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Invoice</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mt-1">
          <b-col
            v-if="types.length > 1"
            cols="6"
          >
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  v-model="parent"
                  :options="types"
                  :reduce="item => item"
                  :clearable="false"
                  label="parent_type"
                  placeholder="Select"
                  @input="onTypeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="type == 'Employer'"
            cols="6"
          >
            <b-form-group
              label="Consultant"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Consultant"
                :rules="'Employer' ? 'required' : ''"
              >
                <v-select
                  v-model="addInvoice.employee_id"
                  :options="employees"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  :disabled="type == null"
                  @input="onEmployeeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="type != 'Employer'"
            cols="6"
          >
            <b-form-group
              label="Consultant"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Consultant"
                rules="required"
              >
                <v-select
                  v-model="addInvoice.employee_ids"
                  :options="employees"
                  :reduce="item => item.id"
                  multiple
                  :clearable="false"
                  placeholder="Select"
                  @input="onEmployeesChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Period"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Period"
                rules="required"
              >
                <flat-pickr
                  v-model="period_date"
                  class="form-control"
                  :config="$store.state.maxMonthConfig"
                  placeholder="MMM-YYYY"
                  :disabled="(addInvoice.employee_id == null && addInvoice.employee_ids == null)"
                  @on-change="periodChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Amount"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Amount"
                rules="required"
              >
                <b-input-group
                  prepend="$"
                >
                  <money
                    v-model="addInvoice.invoice_amount"
                    :min="0"
                    :step="0.5"
                    v-bind="money"
                    :disabled="(addInvoice.employee_id == null && addInvoice.employee_ids == null)"
                    class="form-control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Number of Hours"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Number of Hours"
                rules="required"
              >
                <b-form-input
                  v-model="addInvoice.hours"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Invoice #"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Invoice"
                rules="required"
              >
                <b-form-input
                  v-model="addInvoice.invoice"
                  :disabled="(addInvoice.employee_id == null && addInvoice.employee_ids == null)"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-show="is_expenses_exist"
            cols="6"
          >
            <b-form-group
              label="Expenses"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Expenses"
                rules="required"
              >
                <b-input-group
                  prepend="$"
                >
                  <money
                    v-model="addInvoice.expenses"
                    :min="0"
                    :step="0.5"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            offset="2"
          >
            <b-form-checkbox
              v-model="addInvoice.is_corrected"
              :disabled="type == null || period_date == null"
              @change="correctedChanged()"
            >
              Corrected Invoice
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="addInvoice.is_corrected"
            cols="6"
          >
            <b-form-group
              label="Invoices"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Invoices"
                :rules="addInvoice.is_corrected ? 'required' : ''"
              >
                <v-select
                  v-model="addInvoice.corrected_invoice_id"
                  :options="correctedInvoices"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Documents
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                v-model="documentNameId"
                :clearable="true"
                placeholder="Select"
                :options="documentNames"
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Attachment"
              label-cols-md="4"
              class="text-right"
            >
              <div class="d-flex">
                <input
                  ref="myFileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="form-control text-left"
                  @change="attahamentFileSelected"
                >
                <b-button
                  variant="outline-primary"
                  class="ml-1 px-1"
                  @click="addFile"
                >
                  Upload
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button-group
              v-for="(option, index) in addInvoice.documents"
              :key="option.id"
              :value="option.id"
              class="mr-1"
            >
              <b-badge
                :href="option.attachment_link"
                :title="option.name"
                target="option.attachment_link"
                variant="light-primary"
                class="rounded-right-0 line-height-inherit"
              >
                <span>{{ option.name }}</span>
              </b-badge>
              <b-button
                variant="danger"
                class="px-50 py-25"
                @click="removeFile(option, index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Invoice
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButtonGroup, BInputGroup, BButton, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import flatPickr from 'vue-flatpickr-component'
import {
  required,
} from '@validations'
import User from '@/js/user'
import Invoices from '@/js/invoices'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'
import Auth from '@/auth/authService'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BButtonGroup,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
  },
  data() {
    return {
      required,
      addInvoice: {
        vendor_id: null,
        employer_id: null,
        employee_id: null,
        employee_ids: null,
        invoice: '',
        expenses: '',
        is_corrected: false,
        corrected_invoice_id: null,
        period: null,
        hours: '',
        received_date: null,
        submited_date: null,
        invoice_amount: '',
        timesheet_ids: [],
        documents: [],
      },
      parent: null,
      employerName: '',
      period_date: null,
      documentNameId: null,
      employees: [],
      companies: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      timeSheets: [],
      types: [],
      employee: null,
      correctedInvoices: [],
      is_expenses_exist: false,
      type_id: null,
      type: null,
      parent_type: null,
      selected_timeSheets: null,
      selectedFile: null,
      documentNames: [],
      cal_total_amount: null,
    }
  },
  mounted() {
  },
  created() {
    if (!User.profile().invoice_exist) {
      if (User.profile().job_exist) {
        window.location.hash = '#/jobs'
      } else if (User.profile().timesheet_exist) {
        window.location.hash = '#/timesheets'
      } else {
        Auth.logout()
      }
      return
    }
    this.types = User.profile().invoice_parents
    if (this.types.length > 1) {
      this.types.splice(0, 1)
    }
    this.employerName = User.profile().name
    if (this.types.length === 1) {
      this.type = this.types[0].parent_type
      const [parent] = this.types
      this.parent = parent
      this.is_expenses_exist = this.parent.is_expenses_exist
      this.addInvoice.employer_id = this.types[0].parent_id
      this.type_id = this.types[0].parent_type_id
      this.addInvoice.employee_id = null
      this.addInvoice.employee_ids = null
      if (this.type.toLowerCase() === 'subvendor') {
        LIST.getSubVendorInvoicesFullList(this, this.addInvoice.employer_id)
        LIST.getDocumentNameFullList(this, 'FA', 'IS')
      } else if (this.type.toLowerCase() === 'suppliers') {
        LIST.getDocumentNameFullList(this, 'FA', 'IP')
        LIST.getSupplierInvoicesFullList(this, this.addInvoice.employer_id)
      } else if (this.type.toLowerCase() === 'referrer') {
        LIST.getDocumentNameFullList(this, 'FA', 'IR')
        LIST.getReferrerInvoicesFullList(this, this.addInvoice.employer_id)
      } else {
        LIST.getDocumentNameFullList(this, 'FA', 'IE')
        LIST.getEmployeeInvoicesFullList(this, this.addInvoice.employer_id)
      }
      this.parent_type = this.types[0].parent_type
    }
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addInvoice.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          let documentExist = false
          this.addInvoice.documents.forEach(elem => {
            const items = this.documentNames.filter(data => data.label.toLowerCase() === 'invoice' && data.id === elem.document_name_id)
            if (items.length !== 0) {
              documentExist = true
            }
          })
          if (documentExist) {
            this.submitted = true
            if (this.period_date != null) {
              const periodDateMonth = this.period_date.split('-')
              this.addInvoice.period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMM').format('MM')}-01-${periodDateMonth[1]}`)
            }
            this.addInvoice.vendor_id = this.addInvoice.employer_id
            this.addInvoice.received_date = Constants.getEprmMonthDateFromString(moment())
            this.addInvoice.submited_date = Constants.getEprmMonthDateFromString(moment())
            if (Number(this.cal_total_amount) === Number(this.addInvoice.invoice_amount)) {
              if (this.type.toLowerCase() === 'vendors') {
                Invoices.addVendorInvoice(this)
              } else if (this.type.toLowerCase() === 'subvendor') {
                Invoices.addSubVendorInvoice(this)
              } else if (this.type.toLowerCase() === 'suppliers') {
                Invoices.addSupplierInvoice(this)
              } else if (this.type.toLowerCase() === 'referrer') {
                Invoices.addReferrerInvoice(this)
              } else {
                Invoices.addEmployerInvoice(this)
              }
            } else {
              this.amountChanged()
            }
          } else {
            this.$swal({
              title: 'Please upload invoice',
            })
          }
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(obj, position) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dis.addInvoice.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addInvoice.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    onTypeChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      this.addInvoice.employee_ids = null
      this.type = this.parent.parent_type
      this.type_id = this.parent.parent_type_id
      this.is_expenses_exist = this.parent.is_expenses_exist
      this.addInvoice.expenses = 0.0
      this.addInvoice.employer_id = this.parent.parent_id
      this.documents = []
      this.addInvoice.employee_id = null
      if (this.type.toLowerCase() === 'vendors') {
        LIST.getDocumentNameFullList(this, 'FA', 'IV')
        LIST.getVendorInvoicesFullList(this, this.addInvoice.employer_id)
      } else if (this.type.toLowerCase() === 'subvendor') {
        LIST.getDocumentNameFullList(this, 'FA', 'IS')
        LIST.getSubVendorInvoicesFullList(this, this.addInvoice.employer_id)
      } else if (this.type.toLowerCase() === 'suppliers') {
        LIST.getDocumentNameFullList(this, 'FA', 'IP')
        LIST.getSupplierInvoicesFullList(this, this.addInvoice.employer_id)
      } else if (this.type.toLowerCase() === 'referrer') {
        LIST.getDocumentNameFullList(this, 'FA', 'IR')
        LIST.getReferrerInvoicesFullList(this, this.addInvoice.employer_id)
      } else {
        LIST.getDocumentNameFullList(this, 'FA', 'IE')
        LIST.getEmployeeInvoicesFullList(this, this.addInvoice.employer_id)
      }
      if (this.period_date !== null) {
        if (this.addInvoice.is_corrected) {
          if (this.type.toLowerCase() === 'referrer') {
            Invoices.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'subvendor') {
            Invoices.getSubVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'suppliers') {
            Invoices.getSupplierCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'vendors') {
            Invoices.getVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else {
            Invoices.getCorrectedInvoices(this, this.addInvoice.employee_id, this.addInvoice.employer_id, this.type_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          }
        }
      }
    },
    amountChanged() {
      this.$swal({
        title: 'proceed',
        text: 'You had changed caliculated amount are you sure want to continue',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.type.toLowerCase() === 'vendors') {
            Invoices.addVendorInvoice(this)
          } else if (this.type.toLowerCase() === 'subvendor') {
            Invoices.addSubVendorInvoice(this)
          } else if (this.type.toLowerCase() === 'suppliers') {
            Invoices.addSupplierInvoice(this)
          } else if (this.type.toLowerCase() === 'referrer') {
            Invoices.addReferrerInvoice(this)
          } else {
            Invoices.addEmployerInvoice(this)
          }
        }
      })
    },
    periodChanged(config, month) {
      this.period_date = month
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.period_date != null) {
        if (this.addInvoice.is_corrected) {
          if (this.type.toLowerCase() === 'referrer') {
            Invoices.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'subvendor') {
            Invoices.getSubVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'suppliers') {
            Invoices.getSupplierCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else if (this.type.toLowerCase() === 'vendors') {
            Invoices.getVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          } else {
            Invoices.getCorrectedInvoices(this, this.addInvoice.employee_id, this.addInvoice.employer_id, this.type_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
          }
        }
        const periodDateMonth = this.period_date.split('-')
        const period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMM').format('MM')}-01-${periodDateMonth[1]}`)
        if (this.type.toLowerCase() === 'employer') {
          const employeeIds = []
          employeeIds.push(this.addInvoice.employee_id)
          LIST.getInvoiceAmount(this, employeeIds, period, this.type_id)
        } else {
          LIST.getInvoiceAmount(this, this.addInvoice.employee_ids, period, this.type_id)
        }
      }
    },
    correctedChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.addInvoice.is_corrected && this.period_date !== null) {
        if (this.type.toLowerCase() === 'referrer') {
          Invoices.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
        } else if (this.type.toLowerCase() === 'subvendor') {
          Invoices.getSubVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
        } else if (this.type.toLowerCase() === 'suppliers') {
          Invoices.getSupplierCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
        } else if (this.type.toLowerCase() === 'vendors') {
          Invoices.getVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
        } else {
          Invoices.getCorrectedInvoices(this, this.addInvoice.employee_id, this.addInvoice.employer_id, this.type_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
        }
      }
    },
    setVendorAmount(amount) {
      this.addInvoice.invoice_amount = amount.amount
      this.addInvoice.expenses = amount.expenses
      this.addInvoice.hours = amount.hours
      this.cal_total_amount = amount.amount
      this.addInvoice.timesheet_ids = amount.timesheet_ids
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setStatus(status) {
      this.status = status
    },
    setCorrectedInvoices(correctedInvoices) {
      this.correctedInvoices = correctedInvoices
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setReferrerTimeSheets(timeSheets) {
      this.timeSheets = timeSheets
    },
    setEmployerTimeSheets(timeSheets) {
      this.timeSheets = timeSheets
    },
    onEmployeeSelected(employee) {
      this.addInvoice.employee_ids = []
      for (let i = 0; i < employee.length; i += 1) {
        this.addInvoice.employee_ids.push(employee[i].id)
      }
      this.onEmployeesChanged()
    },
    onEmployeeChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.addInvoice.employee_id !== null) {
        this.addInvoice.invoice_amount = ''
        if (this.period_date !== null) {
          if (this.addInvoice.is_corrected) {
            if (this.type.toLowerCase() === 'referrer') {
              Invoices.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'subvendor') {
              Invoices.getSubVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'suppliers') {
              Invoices.getSupplierCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'vendors') {
              Invoices.getVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else {
              Invoices.getCorrectedInvoices(this, this.addInvoice.employee_id, this.addInvoice.employer_id, this.type_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            }
          }
          const periodDateMonth = this.period_date.split('-')
          const period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMM').format('MM')}-01-${periodDateMonth[1]}`)
          if (this.type.toLowerCase() === 'employer') {
            const employeeIds = []
            employeeIds.push(this.addInvoice.employee_id)
            LIST.getInvoiceAmount(this, employeeIds, period, this.type_id)
          }
        }
      } else {
        this.addInvoice.hours = ''
        this.addInvoice.invoice_amount = ''
      }
    },
    onEmployeesChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.addInvoice.employee_ids.length === 0) {
        this.addInvoice.employee_ids = null
      }
      if (this.addInvoice.employee_ids == null) {
        this.addInvoice.invoice = null
        this.addInvoice.submitted_date = null
        this.addInvoice.period_date = null
        this.addInvoice.hours = ''
        this.addInvoice.invoice_amount = ''
        this.submitted_date = null
        this.period_date = null
      } else {
        this.addInvoice.invoice_amount = ''
        if (this.period_date != null) {
          if (this.addInvoice.is_corrected) {
            if (this.type.toLowerCase() === 'referrer') {
              Invoices.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'subvendor') {
              Invoices.getSubVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'suppliers') {
              Invoices.getSupplierCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else if (this.type.toLowerCase() === 'vendors') {
              Invoices.getVendorCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            } else {
              Invoices.getCorrectedInvoices(this, this.addInvoice.employee_id, this.addInvoice.employer_id, this.type_id, moment(this.period_date, 'MMM-YYYY').format('MM'), moment(this.period_date, 'MMM-YYYY').format('YYYY'))
            }
          }
          const periodDateMonth = this.period_date.split('-')
          const period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMM').format('MM')}-01-${periodDateMonth[1]}`)
          LIST.getInvoiceAmount(this, this.addInvoice.employee_ids, period, this.type_id)
        }
      }
    },
    invoiceAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invoiceNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/invoices').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
