import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const invoice = `${HOST}invoice`
const subVendorInvoice = `${HOST}invoice-subvendor`
const employerInvoice = `${HOST}invoice-employer`
const supplierInvoice = `${HOST}invoice-supplier`
const referrerInvoice = `${HOST}invoice-referrer`
const vendorInvoice = `${HOST}invoice-vendor`

export default {
  getInvoices(context1) {
    const context = context1
    const queryParams = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1, type: context.type,
      },
    }
    if (context.month_date !== null) {
      queryParams.params.start_date = context.month_date.date
      queryParams.params.start_month = context.month_date.month
      queryParams.params.start_year = context.month_date.year
    }
    axios.get(invoice, queryParams).then(response => {
      context.setInvoices(response.data)
    }, () => {
    })
  },
  getSubVendorCorrectedInvoices(context1, parentId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: parentId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/subvendor/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getReferrerCorrectedInvoices(context1, parentId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: parentId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/referrer/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getEmployerCorrectedInvoices(context1, parentId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: parentId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/employer/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getSupplierCorrectedInvoices(context1, parentId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: parentId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/supplier/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getVendorCorrectedInvoices(context1, parentId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: parentId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/vendor/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getCorrectedInvoices(context1, employeeId, userId, parentTypeId, Month, Year) {
    const context = context1
    const queryParams = {
      params: {
        parent_id: userId, employee_id: employeeId, parent_type_id: parentTypeId, month: Month, year: Year, date: 1,
      },
    }
    context.$vs.loading()
    axios.get(`${invoice}/corrected`, queryParams).then(response => {
      context.$vs.loading.close()
      context.setCorrectedInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  addSubVendorInvoice(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(subVendorInvoice, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  updateInvoice(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.put(`${invoice}/external/${id}`, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotUpdated(error.response.data.return_message)
    })
  },
  addEmployerInvoice(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(employerInvoice, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  addSupplierInvoice(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(supplierInvoice, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  addReferrerInvoice(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(referrerInvoice, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  addVendorInvoice(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(vendorInvoice, context.addInvoice).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  getInvoiceById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${invoice}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setInvoice(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invocieNotFound(error.response.data.return_message)
    })
  },
}
